/* @import url('https://fonts.googleapis.com/css2?family=Literata&display=swap');

html,body{
  font-family: 'Literata', sans-serif;
} */

.c-dashboardInfo {
  margin-bottom: 15px;
}

.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 20px 25px 20px;
  height: 100%;
}

.wrapContentLine .wrapContent {
  border-radius: 7px;
}

.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.5em;
}

.c-dashboardInfo span {
  display: block;
}

.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 1.5em;
  color: #323c43;
}

.c-dashboardInfo .wrap:after,
.wrapContentLine .wrapContent:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}

.c-dashboardInfo:nth-child(odd) .wrap:after,
.wrapContentLine:nth-child(odd) .wrapContent:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}

.c-dashboardInfo:nth-child(even) .wrap:after,
.wrapContentLine:nth-child(even) .wrapContent:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}

.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}

.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}

.tracking-detail {
  padding: 3rem 0;
}

#tracking {
  margin-bottom: 1rem;
}

[class*="tracking-status-"] p {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

[class*="tracking-status-"] {
  padding: 1.6rem 0;
}

.tracking-list {
  border: 1px solid #e5e5e5;
}

.tracking-item {
  border-left: 4px solid #00ba0d;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}

.tracking-item:last-child {
  padding-bottom: 4rem;
}

.tracking-item .tracking-date {
  margin-bottom: 0.5rem;
}

.tracking-item .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.tracking-item .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}

.tracking-item .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.tracking-item .tracking-icon {
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #fff;
  color: #fff;
}

.tracking-item-pending {
  border-left: 4px solid #d6d6d6;
  position: relative;
  padding: 2rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 3rem;
  min-height: 5rem;
}

.tracking-item-pending:last-child {
  padding-bottom: 4rem;
}

.tracking-item-pending .tracking-date {
  margin-bottom: 0.5rem;
}

.tracking-item-pending .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.tracking-item-pending .tracking-content {
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
}

.tracking-item-pending .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.tracking-item-pending .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  color: #d6d6d6;
}

.tracking-item-pending .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

.tracking-item .tracking-icon.status-current {
  width: 1.9rem;
  height: 1.9rem;
  left: -1.1rem;
}

.tracking-item .tracking-icon.status-intransit {
  color: #00ba0d;
  font-size: 0.6rem;
}

.tracking-item .tracking-icon.status-current {
  color: #00ba0d;
  font-size: 0.6rem;
}

@media (min-width: 992px) {
  .tracking-item {
    margin-left: 10rem;
  }

  .tracking-item .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }

  .tracking-item .tracking-date span {
    display: block;
  }

  .tracking-item .tracking-content {
    padding: 0;
    background-color: transparent;
  }

  .tracking-item-pending {
    margin-left: 10rem;
  }

  .tracking-item-pending .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }

  .tracking-item-pending .tracking-date span {
    display: block;
  }

  .tracking-item-pending .tracking-content {
    padding: 0;
    background-color: transparent;
  }
}

.tracking-item .tracking-content {
  font-weight: 600;
  font-size: 17px;
}

.blinker {
  border: 7px solid #e9f8ea;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

.container {
  position: relative;
  width: 100%;
}

.container .box {
  position: relative;
  margin-top: 1%;
  border: 0.5px solid lightgrey;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  transition: .9s;
  display: flex;
}

.preview-card {
  position: relative;
  margin: 15px;
  background: #fff;
  box-shadow: 0px 3px 10px rgba(34, 35, 58, 0.2);
  padding: 5px 25px 30px;
  padding-left: 30px !important;
  border-radius: 25px;
  transition: all 0.3s;
}

@media screen and (max-width: 992px) {
  .preview-card {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .preview-card {
    min-height: auto;
    height: auto;
    margin: 120px auto;
  }
}

@media screen and (max-height: 500px) and (min-width: 992px) {
  .preview-card {
    height: auto;
  }
}

.preview-card__item {
  align-items: center;
}

@media screen and (max-width: 768px) {
  .preview-card__item {
    flex-direction: column;
  }
}

.preview-card__item.swiper-slide-active .blog-slider__img img {
  opacity: 1;
  transition-delay: 0.3s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>* {
  opacity: 1;
  transform: none;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(1) {
  transition-delay: 0.3s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(2) {
  transition-delay: 0.4s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(3) {
  transition-delay: 0.5s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(4) {
  transition-delay: 0.6s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(5) {
  transition-delay: 0.7s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(6) {
  transition-delay: 0.8s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(7) {
  transition-delay: 0.9s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(8) {
  transition-delay: 1s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(9) {
  transition-delay: 1.1s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(10) {
  transition-delay: 1.2s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(11) {
  transition-delay: 1.3s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(12) {
  transition-delay: 1.4s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(13) {
  transition-delay: 1.5s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(14) {
  transition-delay: 1.6s;
}

.preview-card__item.swiper-slide-active .blog-slider__content>*:nth-child(15) {
  transition-delay: 1.7s;
}

.preview-card__content {
  padding-right: 25px;
}

@media screen and (max-width: 768px) {
  .preview-card__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}

@media screen and (max-width: 576px) {
  .preview-card__content {
    padding: 0;
  }
}

.preview-card__content>* {
  transform: translateY(25px);
  transition: all 0.4s;
}

.preview-card__title {
  font-size: 28px;
  font-weight: 700;
  color: #0d0925;
}

.preview-card__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}

.preview-card__button {
  display: inline-flex;
  background-image: linear-gradient(147deg, #000 0%, #000 74%);
  padding: 15px 35px;
  margin-bottom: 30px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 3px 10px rgba(252, 56, 56, 0.4);
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}

.preview-card__button:hover {
  color: #989898;
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .preview-card__button {
    width: 100%;
  }
}

.timeline-steps-milestone-dash {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

.timeline-steps-milestone-dash .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem
}

.timeline-steps-milestone-dash .timeline-content-milestone-dash {
  width: 10rem;
  text-align: center;
  position: inherit;
  /* top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}

.timeline-steps-milestone-dash .timeline-content-milestone-dash .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6
}

.timeline-steps-milestone-dash .timeline-content-milestone-dash .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: .5
}

.timeline-steps-milestone-dash-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

.timeline-steps-milestone-dash-list .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem
}

.timeline-steps-milestone-dash-list .timeline-content-milestone-dash {
  width: 10rem;
  text-align: center;
}

.timeline-steps-milestone-dash-list .timeline-content-milestone-dash .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6
}

.timeline-steps-milestone-dash-list .timeline-content-milestone-dash .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: .5
}

.bi .bi-x-circle-fill {
  font-size: 3em !important;
  color: red !important;
}

.cards {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  width: 140px !important;
  height: auto !important;
  padding-bottom: 1rem !important;
  margin-top: 0rem !important;
}

.operationCard {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  width: 100% !important;
  height: auto !important;
  padding: 1rem !important;
  margin-top: 0rem !important;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 15px;
}

.listDashCards {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  width: auto !important;
  height: auto !important;
  padding-bottom: 1rem !important;
}

.item {
  padding-left: 5px;
  padding-right: 5px;
}

.item-card {
  transition: 0.5s;
  cursor: pointer;
}

.item-card-title {
  font-size: 20px;
  transition: 1s;
  cursor: pointer;
}

.item-card-title i {
  font-size: 20px;
  transition: 1s;
  cursor: pointer;
  color: #ffa710 !important;
}

.card-text {
  height: auto;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .dashCards {
    display: flex !important;
    flex-wrap: initial !important;
    padding: 5px !important;
    overflow-x: auto !important;
  }

  .cards {
    margin-right: 20px;
    margin-left: 20px;
  }

  .smallScreen{
    margin-left: "auto !important";
    margin-right: "auto !important";
  }

  .milestoneDash {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    width: 100% !important;
    height: auto !important;
    padding-bottom: 1rem !important;
  }
}
.dashCards {
  padding: 0px !important;
}

.milestoneDash {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  /* padding-bottom: 1rem !important; */
  height: auto !important;
}

.milestoneDash:hover{
  transform: scale(1) !important;
}

.timeline-step:hover{
  transform: scale(1.1);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion {
  width: 100%;
}

.item {
  border: 0.5px solid lightgrey;
  margin-bottom: 5px;
  padding: 0px 20px;
}

.title {
  color: #85662b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content {
  color: black;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

/* Sidebar */
.sidebar {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 100%;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

@media (min-width: 991.98px) {
  main {
    padding-left: 10rem;
  }
}

.mobileCard{
  padding: 0 !important;
}

.list-group-item {
  padding: 0.5rem !important;
}

.downloadFile{
  text-decoration: none !important;
  color: black !important;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.reactLoading {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}

.reactLoadingDash{
  position: relative !important;
  left: 50% !important;
}

.reactLoadingDashRTO{
  position: relative !important;
  left: 45% !important;
}

.soften {
  height: 1px !important;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  border: 0 !important;
}

.softenDash {
  height: 1px !important;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  border: 0 !important;
}

hr{
  margin: auto !important;
  color: black !important;
  background-color: white !important;
  border: 0 !important;
  opacity: 1 !important;
}

.editFormHR{
  margin: auto !important;
  color: black !important;
  background-color: black !important;
  border: 1 !important;
  opacity: 1 !important;
}

.reportsPointer {
  cursor: pointer !important;
}

.dashboardLink{
  text-decoration: none !important;
  color:  black !important;
}

.topBadge{
  top: 0.5rem !important;
}

.tmDashContainers{
  padding: 0px !important;
}

.tmDashContainersStock{
  padding: 0px !important;
}


.tableHoverBtn::before {
  content: '';
  position: absolute;
  left: 0px;
  bottom:0px;
  z-index:-1;
  width: 0%;
  height:1px;
  background: #6098FF;
  box-shadow: inset 0px 0px 0px #6098FF;
  display: block;
  -webkit-transition: all 0.4s cubic-bezier(.5, .24, 0, 1);
  transition: all 0.4s cubic-bezier(.5, .24, 0, 1)
}

.tableHoverBtn:hover{
  border-left:1px solid #000000;
  border-right:1.5px solid #000000;
  border-top: 1.5px solid #000000;
  border-bottom: 1px solid #000000;
}

.shP{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.releasePendingTD{
  text-align: center  ;
}


/* table thead th {
  padding: 3px !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
  width: 25vw !important;
  background: white !important;
} */

/* table thead tr:nth-child(2) {
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
} */

/* table thead th:nth-child(2) {
  position: sticky !important;
  left: 0 !important;
  z-index: 10 !important;
}
table tbody th {
  position: sticky !important;
  left: 0 !important;
  background: white !important;
  z-index: 1 !important;
} */

/* table tbody td {
  position: static !important;
  top: 0 !important;
  z-index: 2 !important;
  width: 25vw !important;
  background: white !important;
  left: 0 !important;
  background: white !important;
  z-index: 1 !important;
} */

/* table tbody td:nth-child(2) {
  position: sticky !important;
  left: 0 !important;
  z-index: 2 !important;
} */

.tableContainer{
  padding-left: 0px !important;
}

.live {
  overflow: hidden;
}
.live > li {
  list-style: none;
  position: relative;
  padding: 0 0 0 2em;
  margin: 0 0 0.5em 10px;
  transition: 0.12s;
}
.live > li::before {
  position: absolute;
  content: "•";
  font-family: Arial;
  color: black;
  top: 3px;
  left: 0;
  text-align: center;
  font-size: 1.5em;
  opacity: 0.5;
  line-height: 0.75;
  transition: 0.5s;
}
.live > li:hover {
  box-shadow: 32px 5px 12px 1px rgb(0 0 0 / 20%);
  transform: scale(1.05,1.05);
}
.live > li:hover::before {
  transform: scale(2);
  opacity: 1;
  text-shadow: 0 0 4px;
  transition: 0.1s;
}

.completed {
  overflow: hidden;
}
.completed > li {
  list-style: none;
  position: relative;
  transition: 0.12s;
}
.completed > li::before {
  position: absolute;
  font-family: Arial;
  color: black;
  top: 3px;
  left: 0;
  text-align: center;
  font-size: 1.5em;
  opacity: 0.5;
  line-height: 0.75;
  transition: 0.5s;
}
.completed > li:hover {
  box-shadow: 0px 5px 12px 1px rgb(0 0 0 / 20%);
  transform: scale(1.05,1.05);
}
.completed > li:hover::before {
  transform: scale(2);
  opacity: 1;
  text-shadow: 0 0 4px;
  transition: 0.1s;
}

.mileDashP{
  margin-bottom: -1rem !important;
}

.listDashP{
  text-align: left !important;
  padding-left: 1rem !important;
  margin-bottom: 0rem !important;
}

.futureIcon{
  width: 2.3rem;
  height: 2.3rem;
}

.futureIconList{
  width: 1.7rem;
  height: 1.7rem;
}
.tableModal{
  margin: 0 !important;
  float: right !important;
  width: 100% !important;
}

.border-right{
  border-right: 1px solid #dee2e6 !important;
}

.tmP{
  float: right !important;
}

table{
  font-size: 13px;
}

table { border-collapse: collapse; width: 100%; }
th{ background: #ffffff !important; padding: 8px 16px; }


.table-responsive {
  overflow: auto;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
}

.blFloatRight{
  float: right !important;
}

.fontSizeInput{
  font-size: small !important;
}