  .responsive-title {
  font-size: 20px;
}  
.financierFont {
  font-size: 15px;
}
.screenSize {
  font-size: 15px;
}.screenSize {
  font-size: 15px;
}




@media (min-width: 320px) {
  .financierFont {
    margin-top: 0px;
    font-size: 9px;
    margin-left: 5px;
  }
}
@media (min-width: 321px) and (max-width: 424px) { 

  .financierFont {
    font-size: 10px;
    margin-left: 2px;
  }
}
@media (min-width: 321px) and (max-width: 424px) { 

  .screenSize {
    font-size: 10px;
    margin-left: 2px;
  }
}
@media (min-width: 425px) and (max-width: 768px){ 

  .financierFont {
    font-size: 12px;
    margin-left: 3px;
  }
}
@media (min-width: 769px) { 

  .financierFont {
    font-size: 15px;
    margin-left: 6px;
  }
}
.segmentFont {
  font-size: 13px;
}

@media (min-width: 320px) {
  .segmentFont {
    font-size: 11px;
    margin-left: 2px;
  }
}
@media (min-width: 321px) and (max-width: 424px) { 

  .segmentFont {
    font-size: 14px;
    /* margin-left: 2px; */
  }
}
@media (min-width: 425px) { 

  .segmentFont {
    font-size: 14px;
    /* margin-left: -10px; */
  }
}

@media (min-width: 440px) {
  .responsive-title {
    font-size: 14px;
  }
}


@media (min-width: 768px) {
  .responsive-title {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .responsive-title {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .responsive-title {
    font-size: 20px;
  }
}